<template>
  <BModal
    id="modal-private-action"
    ref="modal-private-action"
    no-stacking
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    size="md"
    hide-header
  >
    <div
      v-if="modalValue.titleModal !== 'Log Riwayat'"
      class="text-center text-black"
    >
      <div class="pt-3">
        <div
          class="text-24-bold"
        >
          {{ modalValue.titleModal }}
        </div>
        <br>
        <div
          class="text-16-normal"
        >
          Kamu akan melakukan {{ subtitle(modalValue.subTitle) }} untuk partner pastikan nominal dan catatan sesuai dengan tujuanmu
        </div>
        <br>
        <div class="text-start mx-1">
          <div class="mb-1">
            Nominal
          </div>
          <b-input-group
            class="mb-1"
            prepend="Rp"
          >
            <b-form-input
              v-model="showNominal"
              placeholder="xx.xxxx"
              type="text"
              @keypress="isNumber($event)"
              @keydown="handleKeyDown"
              @input="formatThousand"
              @paste.prevent="checkPaste"
            />
          </b-input-group>
          <div class="mb-1">
            Jenis Transaksi
          </div>
          <b-form-input
            v-model="modalValue.subTitle"
            class="mb-1"
            disabled
          />
          <div
            v-if="modalValue.titleModal === 'Transfer Saldo'"
            class="mb-1"
          >
            <div class="mb-1">
              Email Penerima Transfer
            </div>
            <v-select
              v-model="modalValue.email"
              label="email"
              :options="modalValue.listEmail"
              :clearable="false"
              placeholder="Pilih Email Penerima Transfer"
              @input="setEmail"
            >
              <template v-slot:option="option">
                {{ option.email }} - Saldo : {{ IDR(option.kmpoin) }}
              </template>
            </v-select>
          </div>
          <div class="mb-1">
            Catatan Transaksi
          </div>
          <b-form-textarea
            v-model="modalValue.notes"
            :placeholder="modalValue.placeholderNotes"
            :formatter="validMaxLength"
          />
          <div class="d-flex justify-between text-sm">
            <div
              v-if="modalValue.notes.length > 249"
              class="text-danger"
            >Maksimal catatan 250 karakter</div>
            <div v-else />
            <div class="text-muted">
              {{ modalValue.notes.length }}/250
            </div>
          </div>
        </div>
        <div class="my-1 mt-5">
          <BButton
            variant="outline-primary"
            class="w-44"
            @click="closeModal"
          >
            Batal
          </BButton>
          <BButton
            :variant="modalValue.notes.length < 3 || disableBtn || modalValue.emailRequired ? 'secondary' : 'primary'"
            class="ml-1 w-44"
            :disabled="modalValue.notes.length < 3 || disableBtn || modalValue.emailRequired"
            @click.once="onFinish"
          >
            <BSpinner
              v-if="isLoading"
              variant="light"
              small
            />
            Terapkan
          </BButton>
        </div>
      </div>
    </div>
    <div
      v-else
      class="mb-2"
    >
      <div
        class=""
        style="text-align: -webkit-right"
        @click="closeModal"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
          alt=""
        >
      </div>
      <div class="text-black font-bold p-1 border-2 mt-2">
        Log Riwayat
      </div>
      <div class="overflow-y-scroll h-[20rem] text-black">
        <div v-if="modalValue.listLogTransactions.length > 0">
          <div
            v-for="(item, idx) in modalValue.listLogTransactions"
            :key="idx"
            class="border-2 p-1"
          >
            {{ logHistory(item) }}
          </div>
        </div>
        <div
          v-else
          class="border-2 p-1 text-center"
        >
          Belum ada Riwayat Transaksi
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import { isNumber } from '@/libs/helpers'
import { IDR } from '@/libs/currency'
import vSelect from 'vue-select'
import { TIME, DDMMYYYY } from '@/libs/filterDate'

export default {
  components: { BButton, BSpinner, vSelect },
  props: {
    modalValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      disableBtn: true,
      isNumber,
      IDR,
      TIME,
      DDMMYYYY,
      showNominal: null,
      inputValue: '',
    }
  },
  methods: {
    onFinish() {
      this.isLoading = true
      this.disableBtn = true
      this.$bvModal.show('modal-pin')
      this.showNominal = null
      this.inputValue = ''
      this.isLoading = false
      this.disableBtn = false
    },
    closeModal() {
      this.isLoading = false
      this.disableBtn = true
      this.showNominal = null
      this.modalValue.notes = ''
      this.modalValue.email = ''
      this.modalValue.nominal = 0
      this.modalValue.emailRequired = false
      this.$nextTick(() => {
        this.$bvModal.hide('modal-private-action')
      })
    },
    setEmail(value) {
      this.modalValue.email = value.email
      if (this.modalValue.email !== '') {
        this.modalValue.emailRequired = false
      }
    },
    setLabel(value) {
      if (value === 'topup') return 'Topup Saldo'
      if (value === 'balance_reduction') return 'Pengurangan Saldo'
      if (value === 'transfer') return 'Transfer Saldo'
      return ''
    },
    formatThousand() {
      this.inputValue = this.showNominal.replace(/\D/g, '')
      const numberValue = parseFloat(this.inputValue) ? parseFloat(this.inputValue) : 0
      const formattedValue = numberValue.toLocaleString()

      if (this.modalValue.subTitle === 'Pengurangan Saldo' && numberValue <= this.modalValue.current_balance) {
        this.showNominal = formattedValue
        this.modalValue.nominal = numberValue
      } else {
        this.showNominal = this.modalValue.current_balance.toLocaleString()
        this.modalValue.nominal = this.modalValue.current_balance
      }

      if (this.modalValue.subTitle === 'Top Up Saldo' && this.inputValue.length <= 8) {
        this.showNominal = formattedValue
        this.modalValue.nominal = numberValue
      }
      if (this.modalValue.nominal === 0 || this.showNominal === '') {
        this.disableBtn = true
      } else {
        this.disableBtn = false
      }
    },
    handleKeyDown(event) {
      let lengthValue = 0
      if (this.modalValue.subTitle === 'Top Up Saldo') {
        lengthValue = 8
      } else {
        lengthValue = this.modalValue.current_balance.toString().length
      }
      if (this.inputValue.length >= lengthValue && event.key !== 'Backspace' && event.key !== 'Delete') {
        event.preventDefault()
      }
    },
    subtitle(value) {
      return value.toLowerCase()
    },
    logHistory(value) {
      const log = `${this.setLabel(value.type_topup)} ${this.IDR(value.nominal)} oleh (User ID ${value.user_id}) ${value.full_name} (${value.email}) pada ${this.DDMMYYYY(value.date_transaction)} pukul ${TIME(value.date_transaction)} - Catatan : ${value.notes}`
      return log
    },
    checkPaste(e) {
      const data = e.clipboardData.getData('Text')
      this.inputValue = data.replace(/\D/g, '')
      const numberValue = parseFloat(this.inputValue) ? parseFloat(this.inputValue) : 0
      this.showNominal = numberValue.toLocaleString()
      this.formatThousand()
    },
    validMaxLength(e) {
      return String(e).substring(0, 250)
    },
  },
}
</script>

<style lang="scss">
    @import '~@core/scss/vue/libs/vue-select.scss';
</style>
